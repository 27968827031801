import React, { useState } from "react";
import classNames from "classnames";
import Personal_information from "./Personal_information";
import Password from "./Password";
import FieldRep from "./FieldRep";
import Company_Profile from "./Company_Profile";
import Privacy_Policy from "./Privacy_Policy";
import { selectCurrentUserData } from "../../../store/auth";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import Beta from "../../Common/beta";
import { useTranslation } from "react-i18next";
import RightSidebar from "./modules/RightSidebar";
import { useLocation, useNavigate } from "react-router-dom";

const Admin_Console = () => {
  const [activeTab, setActiveTab] = useState("personal");
  const userDetails = useSelector(selectCurrentUserData);
  const [userData, setUserData] = useState(userDetails);
  const { t } = useTranslation();

  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.activeTab) {
      setActiveTab(location.state.activeTab);
    }
  }, [location.state]);

  useEffect(() => {
    setUserData(userData);
  }, [userData]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const [toggle, setToggle] = useState(false);

  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(false);

  return (
    <section className="admin_console">
      <div className="title">
        <h1 className="text-2xl font-semibold"> {t(`lang.AC`)} </h1>
      </div>

      <div className="mt-10">
        <div className="w-full">
          <ul className="flex flex-row bg-[#F6F2F9] items-center w-full  text-sm p-2 md:p-4 space-x-1 rounded-md overflow-auto">
            <li>
              <button
                className={classNames("w-max p-2 px-4 font-[500] rounded-md", {
                  "bg-white text-[#4F008A] ": activeTab === "personal",
                })}
                onClick={() => handleTabClick("personal")}
              >
                {t(`lang.PI`)}
              </button>
            </li>
            <li>
              <button
                className={classNames("w-max p-2 px-4 font-[500] rounded-md", {
                  "bg-white text-[#4F008A]": activeTab === "password",
                })}
                onClick={() => handleTabClick("password")}
              >
                {t(`lang.PW`)}
              </button>
            </li>

            <li>
              <button
                className={classNames("w-max p-2 px-4 font-[500] rounded-md", {
                  "bg-white text-[#4F008A]": activeTab === "notification",
                })}
                onClick={() => handleTabClick("notification")}
              >
                {t(`lang.NFC`)}
              </button>
            </li>

            <li>
              <button
                className={classNames("w-max p-2 px-4 font-[500] rounded-md", {
                  "bg-white text-[#4F008A]": activeTab === "field-rep",
                })}
                onClick={() => handleTabClick("field-rep")}
              >
                {t(`lang.FR`)}
              </button>
            </li>

            <li>
              <button
                className={classNames("w-max p-2 px-4 font-[500] rounded-md", {
                  "bg-white text-[#4F008A]": activeTab === "company",
                })}
                onClick={() => handleTabClick("company")}
              >
                {t(`lang.CP`)}
              </button>
            </li>

            <li>
              <button
                className={classNames("w-max p-2 px-4 font-semibold", {
                  "bg-white text-[#4F008A] rounded-md": activeTab === "privacy",
                })}
                onClick={() => handleTabClick("privacy")}
              >
                {t(`lang.PPPP`)}
              </button>
            </li>
          </ul>
        </div>
        <div className="w-full">
          {activeTab === "personal" && (
            <Personal_information userData={userData} />
          )}
          {activeTab === "password" && <Password />}
          {activeTab === "notification" && <div>Notification</div>}
          {activeTab === "field-rep" && <FieldRep />}
          {activeTab === "company" && <Company_Profile setToggle={setToggle} />}
          {activeTab === "privacy" && <Privacy_Policy />}
        </div>
      </div>

      {/* right_side_modal */}
      <div className="console_right">
        {toggle && (
          <div className="item_container">
            <RightSidebar setToggle={setToggle} setOpenModal={setOpenModal} />
          </div>
        )}
      </div>

      {/* modals */}
      <div className="success_modal w-full">
        {openModal && (
          <div className="fixed left-0 flex items-center justify-center top-0 w-full h-full bg-black/60">
            <div className="bg-white min-h-[25rem] md:p-[1.6rem] p-[1rem] max-w-[30rem] w-[90%] rounded-[8px]">
              <div className="container flex w-full items-end justify-end">
                <button onClick={() => setOpenModal(false)}>
                  <img src="/svgs/cancel-modal.svg" alt="" />
                </button>
              </div>
              <div className="flex w-full items-center justify-center">
                <img src="/svgs/mark.svg" alt="" />
              </div>

              <span className="block text-center font-bold text-[1.1rem] mt-[1.4rem]">
                Field Rep Added Successfully!
              </span>
              <small className="mt-[1rem] text-[#555555] text-center block">
                Field Representativect has been successfully added
              </small>

              <div className="button_flex flex items-center justify-center gap-[1.5rem] mt-[4rem]">
                <button
                  onClick={() => navigate("/")}
                  className="w-[12rem] rounded-full bg-white border-solid border-[1px] border-[#3DA84A] text-[.88rem] text-[#3DA84A] py-[.5rem]"
                >
                  Go to dashboard
                </button>

                <button
                  // onClick={() => navigate("/")}
                  className="w-[12rem] rounded-full text-white bg-[#3DA84A] text-[.88rem] py-[.5rem]"
                >
                  View Rep
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Admin_Console;
