import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../api";
import moment from "moment";

export const CustomerApi = createApi({
  reducerPath: "CustomerApi",
  baseQuery: baseQuery,

  endpoints: (builder) => ({
    getAllCustomer: builder.query({
      query: (month) => ({
        url: `/customer/all`,
        method: "GET",
      }),
    }),
    getSingleCustomer: builder.query({
      query: (userId) => ({
        url: `/customer/single/customer/${userId}`,
        method: "GET",
      }),
    }),
    getAllCustomerActivities: builder.query({
      query: (userId) => ({
        url: `/report/all/customer/activities/${userId}`,
        method: "GET",
      }),
    }),
    getAllCustomerPayment: builder.query({
      query: (userId) => ({
        url: `/report/all/customer/product/${userId}`,
        method: "GET",
      }),
    }),
    getAllCustomerReport: builder.query({
      query: (userId) => ({
        url: `/report/all/customer/${userId}`,
        method: "GET",
      }),
    }),
    getAllCustomerDebtorReport: builder.query({
      query: (userId) => ({
        url: `/report/all/customer/debtor/${userId}`,
        method: "GET",
      }),
    }),
    getAllCustomerStockReport: builder.query({
      query: (userId) => ({
        url: `/report/all/customer/stock/${userId}`,
        method: "GET",
      }),
    }),

    getSingleCustomer: builder.query({
      query: (userId) => ({
        url: `/customer/single/customer/${userId}`,
        method: "GET",
      }),
    }),
    getAllCustomerActivities: builder.query({
      query: (userId) => ({
        url: `/report/all/customer/activities/${userId}`,
        method: "GET",
      }),
    }),
    getAllCustomerPayment: builder.query({
      query: (userId) => ({
        url: `/report/all/customer/product/${userId}`,
        method: "GET",
      }),
    }),
    getAllCustomerReport: builder.query({
      query: (userId) => ({
        url: `/report/all/customer/${userId}`,
        method: "GET",
      }),
    }),
    getAllCustomerDebtorReport: builder.query({
      query: (userId) => ({
        url: `/report/all/customer/debtor/${userId}`,
        method: "GET",
      }),
    }),
    getAllCustomerStockReport: builder.query({
      query: (userId) => ({
        url: `/supplier/order/customer/${userId}`,
        method: "GET",
      }),
    }),

    updateCustomer: builder.mutation({
      query: ({ form, id }) => ({
        url: `/customer/${id}`,
        method: "PUT",
        body: form,
      }),
    }),

    deleteCustomer: builder.mutation({
      query: (id) => ({
        url: `/customer/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["getSingleCustomer"],
    }),

    deactivateCustomer: builder.mutation({
      query: ({ id, body }) => ({
        url: `/customer/deactivate/${id}`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["getSingleCustomer"],
    }),

    activateCustomer: builder.mutation({
      query: ({ id, body }) => ({
        url: `/customer/deactivate/${id}`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["getSingleCustomer"],
    }),

    getAllLeads: builder.query({
      query: (month) => ({
        url: `/leads/company`,
        method: "GET",
      }),
    }),
    getAllVisit: builder.query({
      query: (month) => ({
        url: `/leads/company`,
        method: "GET",
      }),
    }),
    getAllCompanyStaff: builder.query({
      query: (month) => ({
        url: `/company/sales-rep`,
        method: "GET",
      }),
    }),

    getPaymentReport: builder.query({
      query: (id) => ({
        url: `/report/product/${id}`,
        method: "GET",
      }),
    }),
    getStockReport: builder.query({
      query: (userId) => ({
        url: `/report/stock/${userId}`,
        method: "GET",
      }),
    }),

    getAllReports: builder.query({
      query: (userId) => ({
        url: `/report/all/${userId}`,
        method: "GET",
      }),
    }),

    getFieldRepLeads: builder.query({
      query: (userId) => ({
        url: `/leads/all/sales-rep/${userId}`,
        method: "GET",
      }),
    }),

    getAllFieldRepActivities: builder.query({
      query: (userId) => ({
        url: `/report/activities/${userId}`,
        method: "GET",
      }),
    }),

    getAllFieldRepReports: builder.query({
      query: (userId) => ({
        url: `/report/all/${userId}`,
        method: "GET",
      }),
    }),

    AssignStaff: builder.mutation({
      query: (data) => ({
        url: `/leads/assign`,
        method: "PUT",
        body: data,
      }),
      transformResponse: (response) => response,
    }),
    updateLeads: builder.mutation({
      query: (data) => ({
        url: `/leads/update/${data?.id}`,
        method: "PATCH",
        body: data,
      }),
      transformResponse: (response) => response.data,
    }),
    getSingleFieldRep: builder.query({
      query: (userId) => ({
        url: `/company/sales-rep/${userId}`,
        method: "GET",
      }),
    }),

    deactivateRep: builder.mutation({
      query: (data) => ({
        url: `/company/manager/deactivate-user`,
        method: "PUT",
        body: data,
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: ["getSingleFieldRep"],
    }),
    getSchedule: builder.query({
      query: ({ startDate, endDate }) => ({
        url: `/visitation?startDate=${moment(startDate).format(
          "YYYY-MM-DD"
        )}&endDate=${moment(endDate).format("YYYY-MM-DD")}`,
        method: "GET",
      }),
    }),

    getLocations: builder.query({
      query: ({ id }) => ({
        url: `/visitation/sales-rep/check-in/${id}?startDate=2023-04-27&endDate=${moment()
          .add(1, "days")
          .format("YYYY-MM-DD")}`,
        method: "GET",
      }),
    }),

    getScheduledVisit: builder.query({
      query: ({ id }) => ({
        url: `/visitation/sales-rep/up-coming/visitations/${id}`,
        method: "GET",
      }),
    }),

    getCompany: builder.query({
      query: () => ({
        url: `/company/all`,
        method: "GET",
      }),
    }),
    addFieldRep: builder.mutation({
      query: (payload) => ({
        url: `/auth/sales-rep/create`,
        method: "POST",
        body: payload,
      }),
      transformResponse: (response) => response.data,
    }),
  }),
});

export const {
  useGetAllCustomerQuery,
  useGetAllLeadsQuery,
  useGetScheduleQuery,
  useGetLocationsQuery,
  useUpdateLeadsMutation,
  useDeactivateRepMutation,
  useAddFieldRepMutation,
  useGetCompanyQuery,
  useGetAllCompanyStaffQuery,
  useAssignStaffMutation,
  useGetPaymentReportQuery,
  useGetStockReportQuery,
  useGetAllActivitiesQuery,
  useGetAllFieldRepActivitiesQuery,
  useGetAllReportsQuery,
  useGetFieldRepLeadsQuery,
  useGetSingleFieldRepQuery,
  useGetAllFieldRepReportsQuery,
  useGetAllCustomerActivitiesQuery,
  useGetAllCustomerPaymentQuery,
  useGetAllCustomerReportQuery,
  useGetAllCustomerDebtorReportQuery,
  useGetAllCustomerStockReportQuery,
  useGetSingleCustomerQuery,
  useUpdateCustomerMutation,
  useDeleteCustomerMutation,
  useDeactivateCustomerMutation,
  useActivateCustomerMutation,
  useGetScheduledVisitQuery,
} = CustomerApi;
